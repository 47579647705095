import React from "react";
import { withRouter } from "react-router-dom";
import {
  // Button,
  Card,
  CardHeader,
  Container,
  Row,
  Col
} from "reactstrap";
import axios from "axios";
import { Table, Divider, Tag, Modal, Button, Layout, Menu, Popconfirm, Input } from 'antd';
import moment from 'moment'
import {BASE_URL} from "../../constants";
// import AddUserItem from "./AddUserItem.js"
// import AssignCoach from "./AssignCoach.js"
// import AddDuration from "./AddDuration.js"

const { Search } = Input;

class TrainerUsers extends React.Component {

  constructor(props) {
    super(props);
    this.userTableColumns = [
      {
        title: 'Name',
        // dataIndex: 'name',
        key: 'name',
        sorter: (a, b) => a.name.localeCompare(b.name),
        render: record => <a onClick={() => this.props.history.push({
          pathname: '/admin/user-details',
          state: { selectedUser: record }
        })
        }>{record.name}</a>,
      },
      {
        title: 'Email',
        dataIndex: 'email',
        key: 'email',
      },
      {
        title: 'Last Active',
        key: 'lastActive',
        render: (record) => <span>{this.findLastActive(record)}</span>,
      },
      {
        title: 'Duration',
        key: 'planLength',
        render: (record) => <a>{record.profile && record.profile.planLength ? record.profile.planLength + " weeks" : "0 Weeks"}</a>,
      },
      {
        title: 'WeeksRemaining',
        key: 'weeksRemaining',
        render: (record) => <a>{typeof record.weeksRemaining !== 'undefined' ? record.weeksRemaining + " week(s)" : "Nil"}</a>,
      },
      {
        title: 'Active Status',
        key: 'isActive',
        render: (record) =>
          <span>
            {record.isActive ?
              <Tag color={'green'} >
                Active</Tag>
              :
              <Tag color={'volcano'} >
                Inactive</Tag>
            }
          </span>
      },
      {
        title: 'Check-in Day',
        key: 'profile',
        filters: [
          {
            text: 'Monday',
            value: 'Monday',
          },
          {
            text: 'Tuesday',
            value: 'Tuesday',
          },
          {
            text: 'Wednesday',
            value: 'Wednesday',
          },
          {
            text: 'Thursday',
            value: 'Thursday',
          },
          {
            text: 'Friday',
            value: 'Friday',
          },
          {
            text: 'Saturday',
            value: 'Saturday',
          },
          {
            text: 'Sunday',
            value: 'Sunday',
          },
        ],
        onFilter: (value, record) => moment.utc(record.profile.startDate).add(6, 'days').format('dddd').startsWith(value),
        render: (record) =>
          <span>
            {moment.utc(record.profile.startDate).add(6, 'days').format('dddd')}
          </span>
      },
      // {
      //   title: 'Action',
      //   key: 'action',
      //   render: (text, record) => (
      //     <div>
      //       <Popconfirm title="Are you sure to change user status" onConfirm={() => this.changeItemStatus(record)}>
      //         <span>
      //           <div style={{ marginBottom: 5 }}>
      //             <Button type="primary" size="small">
      //               Change Status</Button>
      //           </div>
      //         </span>
      //       </Popconfirm>
      //       <div style={{ marginBottom: 5 }}>
      //         <Button type="primary" size="small" onClick={() => this.showAddCoachModal(record)}>
      //           Assign Coach</Button>
      //       </div>
      //       <div>
      //         <Button type="primary" size="small" onClick={() => this.showDurationModal(record)}>
      //           Add Duration</Button>
      //       </div>
      //     </div>
      //   ),
      // },
    ];

    this.state = {
      allUsers: [],
      currentUsers: [],
      loadingUsers: false,
      allTrainers: [],
      allAdmins: [],
      toggleAddModal: false,
      toggleAddCoachModal: false,
      addUserRole: 'user',
      selectedUser: {},
      toggleAddDurationModal: false,
      searchText: '',
      userData: {},
      total: 0,
      limit: 20,
      offset: 0,
    }
  }

  async componentWillMount() {
    let { userData } = this.state;
    if (localStorage.loggedInUser) {
      userData = JSON.parse(localStorage.loggedInUser)
      this.setState({ userData })
    }
    this.getAllUsers(this.state.limit, this.state.offset);
    this.getUserDailyData();
    this.findCurrentWeek();
  }

  async getAllUsers(limit, offset, name='',email='') {
    this.setState({ loadingUsers: true, toggleAddModal: false, toggleAddCoachModal: false, toggleAddDurationModal: false })
    let allUsers = [];

    axios.get(`${BASE_URL}/api/admin-users?limit=${limit}&offset=${offset}&name=${name}&email=${email}`)
      .then(res => {
        if (res.data && res.data.users) {
          let dataArray = res.data.users;
          if (dataArray && dataArray.length) {
            dataArray.sort(function (a, b) {
              return new Date(b.createdAt) - new Date(a.createdAt);
            });
          }
          dataArray.forEach(element => {
            if (element.role === 'user') {
              let proIndex = res.data.userProfiles.findIndex(e => e.userId === element._id && e.coach);
              if (proIndex !== -1 ) {
                element.profile = res.data.userProfiles[proIndex];
                if(element.profile && element.profile.coach){
                  let endDate = moment.utc(element.profile.startDate,'YYYY-MM-DD').add((parseInt(element.profile.actualPlanLength)+1) * 7, 'days').format('MMMM DD, YYYY');
                  const diff =  moment().diff(endDate, 'days')
                  if(diff<0){
                    allUsers.push(element);
                    //console.log('endDate',element.userId , element.profile, endDate, diff)
                  }
                }
              }
              //allUsers.push(element);
            }
          });
          // if (res.data.userProfiles) {
          //   allTrainers.forEach(element => {
          //     let tempArray = res.data.userProfiles.filter(e => e.coach === element.email);
          //     element.assignedUser = tempArray.length ? tempArray.length : 0;
          //   });
          // }
         /* allUsers.forEach(element => {
            if (element.profile && element.profile.coach === this.state.userData.email) {
              trainedUsers.push(element);
            }
          });*/
        }
        this.setState({
          allUsers: allUsers,
          total: res.data.total,
          offset: this.state.offset + res.data.users.length
        });
        this.findCurrentWeek();
      })
      .catch(err => {
        this.setState({ loadingUsers: false, });
      });

  };
  
  async getAllTrainers(limit=10, offset=0, name='',email='') {
    this.setState({
      toggleAddModal: false,
      toggleAddCoachModal: false,
      toggleAddDurationModal: false
    })
    await axios.get(`${BASE_URL}/api/admin-users/trainers?limit=${limit}&offset=${offset}&name=${name}&email=${email}`)
      .then(res => {
        let allTrainers = [];
        if (res.data) {
          if (res.data.users) {
            let dataArray = res.data.users;
            if (dataArray && dataArray.length) {
              dataArray.sort(function (a, b) {
                return new Date(b.createdAt) - new Date(a.createdAt);
              });
            }
            dataArray.forEach(element => {
              if (element.role === 'trainer') {
                allTrainers.push(element);
              }
            });
            // this.setState({
            //   loadingUsers: false,
            //   allUsers, allTrainers, allAdmins
            // });
          }
        }
        this.setState({
          loadingUsers: false,
          allTrainers,
        });
        this.findCurrentWeek();
      })
      .catch(err => {
        this.setState({loadingUsers: false,});
      });
  };

  async getUserDailyData() {

    await axios.get(`${BASE_URL}/api/fitness-data/`)
      .then(async res => {
        await this.formatUsersLastActive(res.data);
      })
      .catch(err => { });

  };

  formatUsersLastActive(data) {
    let { allUsers } = this.state;

    data.forEach(element => {
      let userIndex = allUsers.findIndex(e => e._id === element.userId);
      if (userIndex !== -1) {
        allUsers[userIndex].lastActive = element.dailyData && element.dailyData[0] ?
          element.dailyData[0].date : null;
      }
    });
    this.setState({ allUsers })
  }

  findCurrentWeek() {
    let { allUsers } = this.state;
    let currentDate = moment.utc(new Date()).format('YYYY-MM-DD');

    allUsers.forEach(selectedUser => {

      if (selectedUser.profile && selectedUser.profile.planLength && selectedUser.profile.startDate) {

        for (let i = 1; i <= selectedUser.profile.planLength; i++) {
          let weekStart = moment.utc(selectedUser.profile.startDate).add((i - 1) * 7, 'days');
          let weekEnd = moment.utc(selectedUser.profile.startDate).add((i * 7) - 1, 'days');
         
          if (currentDate >= moment.utc(weekStart).format("YYYY-MM-DD") && currentDate <= moment.utc(weekEnd).format("YYYY-MM-DD")) {
            selectedUser.weeksRemaining = (selectedUser.profile.planLength - i);
            break;
          } else if (currentDate < moment.utc(weekStart).format("YYYY-MM-DD")) {
            selectedUser.weeksRemaining = selectedUser.profile.planLength;
            break
          }else{
            const duration = moment.duration(moment.utc(new Date()).diff(weekEnd));
            const weeks = duration.asWeeks();
            if(weeks<2)
              selectedUser.weeksRemaining = Math.round(-1 * weeks)
          }
        }

      }
    });
    let filteredUsers = allUsers.filter(e => typeof e.weeksRemaining !== 'undefined');
    const currentUsers = allUsers;//[...filteredUsers]
    this.setState({ loadingUsers: false, allUsers: filteredUsers, currentUsers })
  }
  
  searchUsersByName = (value) => {
    /*const currentUsers = []
    const {allUsers} = this.state;
    allUsers.forEach(selectedUser => {
      if(selectedUser.name.toLowerCase().includes(value.toLowerCase())){
        currentUsers.push(selectedUser)
      }
    })
    this.setState({currentUsers})*/
  
    if(this.timeout){
      clearTimeout(this.timeout)
    }
    const _value = value
    this.timeout = setTimeout(()=>{
      this.getAllUsers(this.state.limit, 0, _value)
    }, 1000);
  }
  
  searchUsersByEmail = (value) => {
    if(this.timeout){
      clearTimeout(this.timeout)
    }
    const _value = value
    this.timeout = setTimeout(()=>{
      this.getAllUsers(this.state.limit, 0, '',_value)
    }, 1000);
  }

  showAddModal(role) {
    this.setState({ toggleAddModal: true, addUserRole: role });
  };

  hideAddModal = () => {
    this.setState({ toggleAddModal: false });
  };

  showAddCoachModal(userData) {
    this.setState({ toggleAddCoachModal: true, selectedUser: userData });
  };

  hideAddCoachModal = () => {
    this.setState({ toggleAddCoachModal: false });
  };

  showDurationModal(userData) {
    this.setState({ toggleAddDurationModal: true, selectedUser: userData });
  };

  hideDurationModal = () => {
    this.setState({ toggleAddDurationModal: false });
  };

  changeItemStatus = async record => {
    record.isActive = !record.isActive;
    await axios.put(`${BASE_URL}/api/admin-users/${record._id}`, record)
      .then(res => {
        this.getAllUsers();
      })
      .catch(err => {
        // dispatch({ type: GET_ERRORS, payload: err.response.data })
        // this.setState({ errors: err.response.data });
      });
  }

  findLastActive(record) {
    if (record.lastActive) {
      return moment.utc(record.lastActive).format('Do MMM YYYY')
    } else if (record.profile && record.profile.startDate) {
      return moment.utc(record.profile.startDate).format('Do MMM YYYY')
    } else if (record.profile && record.profile.updatedAt) {
      return moment.utc(record.profile.updatedAt).format('Do MMM YYYY')
    } else if (record.updatedAt) {
      return moment.utc(record.updatedAt).format('Do MMM YYYY')
    }
  }
  
  onChange = (pagination, filters, sorter, extra) => {
    this.setState({
      offset: pagination.current * pagination.pageSize
    })
    this.getAllUsers(pagination.pageSize,(pagination.current-1) * pagination.pageSize )
  };

  render() {
    const { allUsers, loadingUsers, allTrainers, allAdmins, currentUsers,
      toggleAddModal, addUserRole, toggleAddCoachModal, selectedUser,
      toggleAddDurationModal } = this.state;

    return (
      <>
        {/* <Header /> */}
        <div className="header bg-gradient-info pb-8 pt-5 pt-md-8">
          <Container fluid>
            <Row>
              <Col xs={4}>
                <Search
                  placeholder="Search By Name"
                  allowClear
                  size="large"
                  onChange={(e)=>{this.searchUsersByName(e.target.value)}}
                  onSearch={this.searchUsersByName}
                />
              </Col>
              <Col xs={4}>
                <Search
                  placeholder="Search By Email"
                  allowClear
                  size="large"
                  onChange={(e)=>{this.searchUsersByEmail(e.target.value)}}
                  onSearch={this.searchUsersByEmail}
                />
              </Col>
            </Row>
          </Container>
        </div>
        {/* Page content */}
        <Container className="mt--7" fluid>
          <Row>
            <Col className="mb-5 mb-xl-0" xl="12">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <Row className="align-items-center">
                    <div className="col">
                      <h3 className="mb-0">All Users</h3>
                    </div>
                    {/* <div className="col text-right">
                      <Button type="primary" size="small" onClick={() => this.showAddModal('user')} >
                        Add User</Button>
                    </div> */}
                  </Row>
                </CardHeader>
                <Table
                  loading={loadingUsers}
                  style={{ background: '#fff' }}
                  columns={this.userTableColumns}
                  dataSource={currentUsers}
                  rowKey={record => record._id}
                  pagination={{total: this.state.total, pageSize: this.state.limit}}
                  onChange={this.onChange}
                />
              </Card>
            </Col>
          </Row>
          {/* {toggleAddModal ?
            <Modal
              title={`Add ${addUserRole}`}
              visible={toggleAddModal}
              closable={true}
              footer={null}
              // confirmLoading={confirmLoading}
              onCancel={this.hideAddModal}
            >
              <AddUserItem userRole={addUserRole} closeModal={() => { this.getAllUsers() }} />
            </Modal>
            : null}

          {toggleAddCoachModal ?
            <Modal
              title="Assign Coach"
              visible={toggleAddCoachModal}
              closable={true}
              footer={null}
              // confirmLoading={confirmLoading}
              onCancel={this.hideAddCoachModal}
            >
              <AssignCoach userData={selectedUser} trainers={allTrainers} closeModal={() => { this.getAllUsers() }} />
            </Modal>
            : null}

          {toggleAddDurationModal ?
            <Modal
              title="Assign Program Duration"
              visible={toggleAddDurationModal}
              closable={true}
              footer={null}
              // confirmLoading={confirmLoading}
              onCancel={this.hideDurationModal}
            >
              <AddDuration userData={selectedUser} trainers={allTrainers} closeModal={() => { this.getAllUsers() }} />
            </Modal>
            : null} */}
        </Container>
      </>
    );
  }
}

export default withRouter(TrainerUsers);
